import React from "react"

import "../styles/layout.css"
import "../styles/site.css"

export default ({ children }) => {
  return (
    <div className="font-sans text-black text-base md:text-base">
      {children}
    </div>
  )
}
